<template>
  <div class="AnnonceItem">
    <div class="gavc-card-row" style="width: 100%;">
      <figure class="gavc-card-row__img">
        <img
          v-if="annonce.images.length > 0"
          class="gavc-img-cover"
          :src="imageUrl(annonce.images[0])"
          alt="image de l'annonce"
          style="max-width: 200px;"
        >
        <img v-else class="gavc-img-cover" :src="require('@/assets/images/default.jpg')" alt="image de l'annonce">
      </figure>
      <div class="gavc-card-row__content gavc-margin-left">
        <div class="gavc-card-row__content--infos">
          <p class="gavc-title-offer">
            {{annonce.offre_cession.entreprise_cedee.name}} - {{annonce.title}}
          </p>
          <p class="gavc-title-price">
            Offre:
            <span
              v-if="annonce.price && annonce.price > 0"
            >
              {{formatThousandSeparatorNumber(Number(annonce.price).toFixed(0))}} €
            </span>
            <span
              v-else-if="annonce.price_category"
            >
              {{priceCategoryRender(annonce.price_category)}}
            </span>
          </p>
          <div class="gavc-card-offer__content--header__icon-text">
            <i class="gavc-icon gavc-icon-pin"></i>{{annonce.offre_cession.entreprise_cedee.city}} (siège social)
          </div>
        </div>
        <div class="gavc-card-row__content--desc gavc-text-small">
          <strong>Description:</strong>
          {{annonce.activite}}
         </div>
         <!--<div class="gavc-card-row__icon-text gavc-text-small">
           <i class="gavc-icon gavc-icon-silhouette"></i>
           {{annonce.offre_cession.nb_candidatures}}
           <span v-if="annonce.offre_cession.nb_candidatures <= 1">
             Candidat
           </span>
           <span v-else>
             Candidats
           </span>
         </div>-->
       </div>
     </div>
  </div>
</template>

<script>
import renderMixins from '@/mixins/renderMixins'
import { apiBaseUrl} from '@/variables/localVariables'
export default {
  name: 'OffreCessionItem',
  props: {
    annonce: Object,
    withBaseApiUrl: {
      default: true,
      type: Boolean
    }
  },
  mixins: [
    renderMixins
  ],
  methods: {
    imageUrl (image) {
      if (this.withBaseApiUrl) {
        return apiBaseUrl + image.file
      }
      return image.file
    }
  }
}
</script>
